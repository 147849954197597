import { createQueryKeys } from '@lukemorales/query-key-factory';
import { QueryObserverOptions, useQuery } from '@tanstack/react-query';

import { useAxios } from '@/api/axiosClient';
import { AttachmentUrlResponse } from '@/api/types';

export const attachmentKeys = createQueryKeys('attachment', {
  getAttachmentFile: ({
    attachmentType,
    filenameId,
  }: {
    attachmentType: string;
    filenameId: string;
  }) => [{ attachmentType, filenameId }],
  getAttachmentUrl: ({
    attachmentType,
    filenameId,
  }: {
    attachmentType: string;
    filenameId: string;
  }) => [{ attachmentType, filenameId }],
});

export const useAttachmentUrlQuery = <T = AttachmentUrlResponse>({
  enabled,
  select,
  throwOnError = true,
  params: { attachmentType, filenameId },
}: Pick<QueryObserverOptions, 'enabled'> & {
  select?: (data: AttachmentUrlResponse) => T;
  throwOnError?: boolean;
  enabled?: boolean;
  params: { attachmentType: string; filenameId: string };
}) => {
  const axiosClient = useAxios();
  const url = `/Attachment/${attachmentType}/url/${filenameId}`;
  return useQuery<AttachmentUrlResponse, unknown, T>({
    staleTime: Infinity,
    queryKey: attachmentKeys.getAttachmentUrl({ attachmentType, filenameId }),
    queryFn: async () => {
      const response = await axiosClient.get(url);
      return response.data;
    },
    enabled,
    select,
    throwOnError: throwOnError,
  });
};
