import { RoleType } from '@/api/types';
import { useMyProfile } from '@/hooks/useMyProfile';

export const useIntegrationsRoleBasedAccessControl = () => {
  const userProfile = useMyProfile();

  return {
    loading: userProfile.isLoading,
    canViewIntegrationsSettings: userProfile.data?.roleType === RoleType.ADMIN,
  };
};
