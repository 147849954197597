import { QueryClient } from '@tanstack/react-query';
import { Axios } from 'axios';
import { t, TFunction } from 'i18next';
import { FieldName } from 'react-hook-form';
import * as yup from 'yup';
import { array, number, object, string } from 'yup';

import { attachmentKeys } from '@/api/attachment';
import { companyKeys } from '@/api/company';
import {
  CompanyHashtag,
  GetBroadcastResponse,
  UserProfileListDetail,
} from '@/api/types';
import { GetUserProfileCondition, userProfileKeys } from '@/api/userProfile';
import { LABELS_COLOR_MAPPING } from '@/constants/label';
import { CreateBroadcastFormValues } from '@/pages/Broadcasts/BroadcastCreateChannel/shared/types';
import { CustomUserProfileFieldWithStaticFieldType } from '@/pages/Contacts/shared/ContactsTable/ContactsFilterDialog';
import { isTransformedLinguagls } from '@/pages/Contacts/shared/types';
import {
  findContactsListUserProfileField,
  findHashtagUserProfileField,
  getLocalisedContactFieldLinguals,
} from '@/pages/Contacts/shared/utils';
import { RuleOptionsValues, RULE_OPTIONS } from '@/utils/rules';
import { notEmpty } from '@/utils/ts-utils';

const getMatchContentInsideCurlyBracketRegex = (i: string) => `\\{${i}\\}`;
export const MATCH_DOUBLE_CURLY_BRACKET_PRESERVE_BRACKETS = /({{[^{|}]+}})/gm;
export const MATCH_DOUBLE_CURLY_BRACKET_CONTENT_REGEX = /\{\{(.*?)}}/gm;
export const MATCH_DOUBLE_CURLY_BRACKET_WITH_NUMBER_REGEX = /{{\d+}}/gm;
const MATCH_AT_SIGN_REGEX = /^@/;

export const fieldNameMap: Partial<
  Record<FieldName<CreateBroadcastFormValues>, string>
> = {
  // t('broadcast.create.channel')
  channels: 'broadcast.create.channel',
  // t('broadcast.create.broadcast-name.label')
  broadcastName: 'broadcast.create.broadcast-name.label',
  // t('broadcast.create.audience.label')
  audience: 'broadcast.create.audience.label',
  // t('broadcast.create.message')
  message: 'broadcast.create.message',
  // t('broadcast.create.message')
  messageType: 'broadcast.create.message',
  // t('broadcast.create.message')
  template: 'broadcast.create.message',
  // t('broadcast.create.dynamic-content.label')
  templateParams: 'broadcast.create.dynamic-content.label',
  // t('broadcast.create.message-tag.label')
  messageTag: 'broadcast.create.message-tag.label',
  // t('broadcast.create.message-otn.label')
  facebookOTNTopicId: 'broadcast.create.message-otn.label',
  // t('broadcast.create.media')
  file: 'broadcast.create.media',
};

// extracts and transforms dynamic field names from dynamic content for cloudapi template
export function transformTemplateParamsToApi({
  templateParams,
  profileFieldNames,
  dynamicUrl,
}: {
  templateParams: { text: string; index: number }[];
  profileFieldNames: string[];
  dynamicUrl?: { text: string; param: string; url: string };
}) {
  const profileFieldNameSet = new Set(profileFieldNames);

  const params: string[] = [];
  templateParams.forEach((param) => {
    param.text
      .match(MATCH_DOUBLE_CURLY_BRACKET_CONTENT_REGEX)
      ?.forEach((match) => {
        const matchWithoutCurlyBrackets = match.replace(
          MATCH_DOUBLE_CURLY_BRACKET_CONTENT_REGEX,
          '$1',
        );
        if (profileFieldNameSet.has(matchWithoutCurlyBrackets)) {
          params.push(`@${matchWithoutCurlyBrackets}`);
        }
      });
  });

  if (dynamicUrl) {
    dynamicUrl.param
      .match(MATCH_DOUBLE_CURLY_BRACKET_CONTENT_REGEX)
      ?.forEach((match) => {
        const matchWithoutCurlyBrackets = match.replace(
          MATCH_DOUBLE_CURLY_BRACKET_CONTENT_REGEX,
          '$1',
        );
        if (profileFieldNameSet.has(matchWithoutCurlyBrackets)) {
          params.push(`@${matchWithoutCurlyBrackets}`);
        }
      });
  }
  return params;
}

export function transformToEditableContentFromApi({
  templateContent,
  templateParams,
}: {
  templateContent: string;
  templateParams: string[];
}) {
  let content = templateContent;
  for (const i in templateParams) {
    const paramPlaceholder = `{{${templateParams[i].replace(
      MATCH_AT_SIGN_REGEX,
      '',
    )}}}`;
    const paramPattern = new RegExp(
      getMatchContentInsideCurlyBracketRegex(i),
      'g',
    );
    content = content.replace(paramPattern, paramPlaceholder.toLowerCase());
  }
  return content;
}

export const getTemplateParamsSchema = (t: TFunction) =>
  array().of(
    object({
      text: string().required(t('general.required-field-validation')),
      index: number().required(t('general.required-field-validation')),
    }),
  );

export const getDynamicUrlSchema = (t: TFunction) =>
  yup.lazy((value) => {
    switch (typeof value) {
      case 'undefined':
        return yup.mixed().nullable();
      default:
        return object({
          text: string().required(t('general.required-field-validation')),
          url: string().required(t('general.required-field-validation')),
          param: string().required(t('general.required-field-validation')),
        });
    }
  });

export const getAudienceSchema = (t: TFunction) =>
  object({
    type: string()
      .oneOf(['list', 'condition'])
      .required(t('general.required-field-validation')),
    conditions: yup.array().when(['type'], ([type]) => {
      if (type === 'condition') {
        return yup
          .array()
          .of(
            yup.lazy((value) => {
              return yup.object({
                category: yup.string().required(),
                type: yup.string().required(),
                // Allow empty contact field (completely empty filters are valid)
                contactField: yup.object({
                  displayName: yup.string(),
                  id: yup.string().required(),
                  value: yup.string().required(),
                }),

                rule: yup.string().required(),
                // Sometimes value can be array if multi-select
                value: Array.isArray(value.value)
                  ? yup
                      .array()
                      // if value can be empty pass validation
                      .when(['rule'], ([rule]) => {
                        if (
                          rule === RULE_OPTIONS.isNull.value ||
                          rule === RULE_OPTIONS.isNotNull.value
                        ) {
                          return yup.array().of(yup.string());
                        }
                        // HACK: special handling for select options
                        if (
                          value.value.length > 0 &&
                          'value' in value.value[0]
                        ) {
                          return yup
                            .array()
                            .of(
                              yup.object({
                                value: yup.string(),
                                displayName: yup.string(),
                                color: yup.string(),
                              }),
                            )
                            .required()
                            .min(1);
                        }
                        return yup.array().of(yup.string()).required().min(1);
                      })
                  : // if value can be empty pass validation
                    yup.string().when(['rule'], ([rule]) => {
                      if (
                        rule === RULE_OPTIONS.isNull.value ||
                        rule === RULE_OPTIONS.isNotNull.value
                      ) {
                        return yup.string();
                      }

                      return yup.string().required().min(1);
                    }),
              });
            }),
          )
          .min(1);
      }
      return yup
        .array()
        .of(
          yup.object({
            category: yup.string().required(),
            type: yup.string().required(),
            contactField: yup.object({
              displayName: yup.string(),
              id: yup.string().required(),
              value: yup.mixed().oneOf(['importFrom']),
            }),
            rule: yup.string().required(),
            value: yup
              .array()
              .of(
                yup.object({
                  value: yup.string(),
                  displayName: yup.string(),
                }),
              )
              .required()
              .min(1),
          }),
        )
        .length(1);
    }),
  });

// extract dynamic veriables from string for manual input
export const extractTemplateParamsFromString = (message = ''): string[] => {
  return (message.match(MATCH_DOUBLE_CURLY_BRACKET_CONTENT_REGEX) || []).map(
    (v) => v.replace(MATCH_DOUBLE_CURLY_BRACKET_CONTENT_REGEX, '@$1'),
  );
};

// cloudapi and possibly 360 dialog?
export const transformDynamicContentTemplateParamsToApi = ({
  templateParams,
  profileFieldNames,
}: {
  templateParams: { text: string; index: number }[];
  profileFieldNames: string[];
}) => {
  let currentIndex = 0;
  return templateParams.reduce<{ text: string; index: number }[]>(
    (acc, param) => {
      const formattedContent = formatTemplateToIndexedVariables({
        content: param.text,
        profileFieldNames,
        startingIndex: currentIndex,
      });

      acc[param.index] = {
        text: formattedContent.content,
        index: param.index,
      };
      currentIndex += formattedContent.endIndex;
      return acc;
    },
    [],
  );
};

export const formatTemplateToIndexedVariables = ({
  content = '',
  profileFieldNames,
  startingIndex = 0,
}: {
  startingIndex?: number;
  content: string;
  profileFieldNames: string[];
}) => {
  let index = 0;
  const profileFieldNameSet = new Set(profileFieldNames);

  return {
    content: content.replace(
      MATCH_DOUBLE_CURLY_BRACKET_CONTENT_REGEX,
      (substring) => {
        const replacedParam = substring.replace(/\{\{(.*?)}}/g, '$1');
        if (profileFieldNameSet.has(replacedParam)) {
          const replacement = `{${startingIndex + index}}`;
          index += 1;
          return replacement;
        }
        return substring;
      },
    ),
    endIndex: index,
  };
};

const getConditionValue = async ({
  t,
  queryClient,
  condition,
  customUserProfileField,
  language,
  axiosClient,
}: {
  t: TFunction;
  axiosClient: Axios;
  queryClient: QueryClient;
  language: string;
  condition: GetUserProfileCondition;
  customUserProfileField: CustomUserProfileFieldWithStaticFieldType;
}) => {
  switch (customUserProfileField.type) {
    case 'UserLanguage':
    case 'SingleLineText':
    case 'MultiLineText':
    case 'Email':
    case 'PhoneNumber':
    case 'Boolean':
    case 'Number':
    case 'FirstName':
    case 'LastName':
    case 'CreatedAt':
    case 'Date':
    case 'DateTime': {
      if (Array.isArray(condition.values)) {
        return condition.values[0];
      }
      return '';
    }
    case 'Channel':
    case 'ContactOwnerField':
    case 'Options':
    case 'TravisUser':
    case 'Collaborators': {
      if (Array.isArray(condition.values)) {
        return condition.values
          .map((val) => {
            const option =
              customUserProfileField.customUserProfileFieldOptions[val];

            // if it's a normal transformed lingual
            if (isTransformedLinguagls(option)) {
              return {
                displayName: getLocalisedContactFieldLinguals({
                  languageOptions: option,
                  language,
                }),
                value: val,
              };
            }
          })
          .filter(notEmpty);
      }
      return '';
    }
    case 'Lists': {
      if (Array.isArray(condition.values)) {
        const promisedValues = condition.values
          .map(async (listId) => {
            const foundList = await queryClient
              .fetchQuery({
                queryKey: userProfileKeys.getUserProfileListDetail({
                  listId: String(listId),
                }),
                queryFn: async ({ signal }) => {
                  const response = await axiosClient.get<UserProfileListDetail>(
                    `/UserProfile/List/${listId}`,
                    {
                      signal,
                    },
                  );
                  return response.data;
                },
                retry: false,
              })
              .catch((e) => {
                console.error(e);
              });

            // if it's a list option
            if (foundList) {
              return {
                displayName: foundList.importName,
                value: String(foundList.id),
              };
            }
          })
          .filter(notEmpty);

        return Promise.allSettled(promisedValues).then((results) =>
          results
            .map((result) => {
              if (result.status === 'fulfilled') {
                return result.value;
              }
              return;
            })
            .filter(notEmpty),
        );
      }
      return '';
    }
    case 'Labels': {
      if (Array.isArray(condition.values)) {
        const promisedValues = condition.values
          .map(async (val) => {
            const searchedHashtags = await queryClient
              .fetchQuery({
                queryKey: companyKeys.getCompanyTags({
                  keyword: val,
                }),
                queryFn: async ({ signal }) => {
                  const response = await axiosClient.get<CompanyHashtag[]>(
                    '/Company/Tags',
                    {
                      signal,
                      params: {
                        keyword: val,
                      },
                    },
                  );
                  return response.data;
                },
                retry: false,
              })
              .catch((e) => {
                console.error(e);
              });
            if (searchedHashtags) {
              const targetHashtag = searchedHashtags.find(
                (label) => label.hashtag === val,
              );
              if (targetHashtag) {
                return {
                  value: targetHashtag.id,
                  displayName: targetHashtag.hashtag,
                  color: LABELS_COLOR_MAPPING[targetHashtag.hashTagColor],
                };
              }
            }

            return undefined;
          })
          .filter(notEmpty);

        return Promise.allSettled(promisedValues).then((results) =>
          results
            .map((result) => {
              if (result.status === 'fulfilled') {
                return result.value;
              }
              return;
            })
            .filter(notEmpty),
        );
      }
      return '';
    }

    default:
      throw new Error(
        t('broadcast.error.unknown-field-type-title', {
          defaultValue: 'Unknown field type',
        }),
      );
  }
};

export const transformContactsFiltersFromApi = async ({
  axiosClient,
  queryClient,
  conditions,
  allCustomUserProfileFields,
  language,
}: {
  axiosClient: Axios;
  queryClient: QueryClient;
  language: string;
  allCustomUserProfileFields: CustomUserProfileFieldWithStaticFieldType[];
  conditions: GetUserProfileCondition[];
}) => {
  const res = conditions.map(async (condition) => {
    let customUserProfileField;
    // exception for lists
    if (condition.fieldName?.toLowerCase() === 'importfrom') {
      customUserProfileField = findContactsListUserProfileField(
        allCustomUserProfileFields,
      );
      // exception for labels/hashtags
    } else if (condition.containHashTag === 'hashtags') {
      customUserProfileField = findHashtagUserProfileField(
        allCustomUserProfileFields,
      );
    } else {
      customUserProfileField = allCustomUserProfileFields.find((field) => {
        if (
          field.fieldName.toLowerCase() === condition.fieldName?.toLowerCase()
        ) {
          return field;
        }
      });
    }

    if (customUserProfileField) {
      const localisedDisplayName = getLocalisedContactFieldLinguals({
        languageOptions: customUserProfileField.customUserProfileFieldLinguals,
        language,
        fallbacks: customUserProfileField.fieldName,
      });

      const value = await getConditionValue({
        t,
        axiosClient,
        condition,
        queryClient,
        customUserProfileField,
        language,
      });
      return {
        category: customUserProfileField.fieldsCategory,
        type: customUserProfileField.type,
        contactField: {
          id: customUserProfileField.id,
          displayName: localisedDisplayName,
          // really random special handling for lists
          value:
            customUserProfileField.fieldName === 'Lists'
              ? 'importFrom'
              : customUserProfileField.fieldName,
        },
        rule: condition.conditionOperator as RuleOptionsValues,
        value,
      };
    }
    return undefined;
  });
  const resolvedResult = await Promise.all(res);

  return resolvedResult.filter(
    (condition) =>
      notEmpty(condition) &&
      condition.value !== undefined &&
      (Array.isArray(condition?.value)
        ? condition?.value && condition.value.length > 0
        : true),
  );
};

interface FileObjectValue {
  file: null | File;
  url: string;
  MIMEType: string | undefined;
  filename: string | undefined;
  id: string | undefined;
  fileSize: number | undefined;
}

export const transformUploadedFileToFile = async ({
  queryClient,
  axiosClient,
  broadcastResponse,
}: {
  queryClient: QueryClient;
  axiosClient: Axios;
  broadcastResponse: GetBroadcastResponse;
}): Promise<FileObjectValue> => {
  if (
    broadcastResponse.campaignChannelMessages.length > 0 &&
    broadcastResponse.campaignChannelMessages[0].uploadedFiles.length > 0
  ) {
    const uploadedFile =
      broadcastResponse.campaignChannelMessages[0].uploadedFiles[0];

    const attachment = await queryClient.fetchQuery({
      queryKey: attachmentKeys.getAttachmentUrl({
        attachmentType: 'Campaign',
        filenameId: uploadedFile.campaignUploadedFileId,
      }),
      queryFn: async () => {
        const res = await axiosClient.get<{ MIMEType: string; url: string }>(
          `/Attachment/Campaign/Url/${uploadedFile.campaignUploadedFileId}`,
        );

        return res.data;
      },
    });

    return {
      url: attachment.url,
      id: uploadedFile.campaignUploadedFileId,
      MIMEType: attachment.MIMEType,
      filename: uploadedFile.filename,
      fileSize: undefined,
      file: null,
    };
  }
  return {
    file: null,
    fileSize: undefined,
    url: '',
    MIMEType: undefined,
    filename: undefined,
    id: undefined,
  };
};
