import { useCompany, useSuspenseCompany } from '@/api/company';
import { CompanyTypeDict } from '@/api/types';
import { useMyProfile } from '@/hooks/useMyProfile';
import { useSuspenseAccessRuleGuard } from '@/pages/Contacts/shared/accessRuleGuard/useAccessRuleGuard';
import useCustomObjectDataAccessControl from '@/pages/CustomObjectData/hooks/useCustomObjectDataAccessControl';
import {
  isEnterprisePlan,
  isFreeOrFreemiumPlan,
  isLegacyPlan,
  isPremiumPlan,
  isProPlan,
} from '@/utils/subscription-plan-checker';

const COMPANIES_TEST_PRIVACY =
  import.meta.env.VITE_COMPANIES_TEST_PRIVACY ?? '';

export const useSettingAccessRuleGuard = () => {
  const accessRulesGuard = useSuspenseAccessRuleGuard();
  const companyId = useSuspenseCompany({
    select: (data) => data.id,
  });

  const currentPlan =
    accessRulesGuard.company.data?.currentPlan.billRecord?.subscriptionPlan;
  const addOnStatus = accessRulesGuard.company.data?.addOnPlanStatus;
  const { data: myProfile } = useMyProfile();
  const isWhatsappQrCodeEnabled = addOnStatus?.isWhatsappQrCodeEnabled;
  const { data: isResellerClient } = useCompany({
    select: (data) => {
      return (
        data.companyType === CompanyTypeDict.resellerClient ||
        data.companyType === CompanyTypeDict.reseller
      );
    },
  });
  const { data: isPaymentFailed } = useCompany({
    select: (data) => {
      return data.isPaymentFailed;
    },
  });

  const { canAccessCustomObjectData, isLoading: isCheckingCustomObjectAccess } =
    useCustomObjectDataAccessControl();

  const isAllowAdmin = (): boolean => {
    if (myProfile?.roleType === 'Admin') {
      return true;
    }
    return false;
  };

  const isAllowAdminAndTeamAdmin = (): boolean => {
    if (
      myProfile?.roleType === 'Admin' ||
      myProfile?.roleType === 'TeamAdmin'
    ) {
      return true;
    }
    return false;
  };

  const isTeamFieldAllowToView = () => {
    if (!currentPlan) return false;
    if (isFreeOrFreemiumPlan(currentPlan) || isProPlan(currentPlan)) {
      return false;
    } else {
      return true;
    }
  };

  const isTeamFieldAllowToEdit = () => {
    if (!currentPlan || !isTeamFieldAllowToView()) return false;
    return isAllowAdmin();
  };

  const isEmailFieldAllowToView = () => {
    //All plan and all role can see now
    return true;
  };

  const isEmailFieldAllowToEdit = () => {
    //All plan can't edit now
    return false;
  };

  const isUsernameFieldAllowToView = () => {
    //All plan and all role can see now
    return true;
  };

  const isUsernameFieldAllowToEdit = () => {
    //All plan can't edit now
    return false;
  };

  const is2FAFieldAllowToView = () => {
    if (!currentPlan) return false;
    if (isFreeOrFreemiumPlan(currentPlan)) {
      return false;
    } else {
      return true;
    }
  };

  const is2FARevokeWarningAllowToView = () => {
    if (!currentPlan) return false;
    if (myProfile?.roleType !== 'Admin') {
      return true;
    } else {
      return false;
    }
  };

  const isQuickSharingTabAllowToView = () => {
    if (!currentPlan) return false;
    if (isLegacyPlan(currentPlan.version)) {
      return isEnterprisePlan(currentPlan) || isWhatsappQrCodeEnabled;
    } else {
      if (
        isWhatsappQrCodeEnabled ||
        isEnterprisePlan(currentPlan) ||
        isPremiumPlan(currentPlan)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const is2FASettingAllowToView = () => {
    if (!currentPlan) return false;
    if (isFreeOrFreemiumPlan(currentPlan)) {
      return false;
    }
    return isAllowAdmin();
  };

  const isTeamManagementTabAllowToView = () => {
    if (!currentPlan) return false;
    if (isFreeOrFreemiumPlan(currentPlan) || isProPlan(currentPlan)) {
      return false;
    }
    return isAllowAdmin();
  };

  const isIpWhitelistingAllowToView = () => {
    if (!currentPlan) return false;
    if (isPremiumPlan(currentPlan) || isEnterprisePlan(currentPlan)) {
      return isAllowAdmin();
    }
    return false;
  };

  const isCompanyDetailTabAllowToView = () => isAllowAdminAndTeamAdmin();
  const isSubscriptionsAllowToView = () => isAllowAdmin();
  const isInboxSettingsAllowToView = () => isAllowAdmin();
  const isWelcomeMessageAllowToView = () => isAllowAdmin();
  const isUserManagementTabAllowToView = () => isAllowAdminAndTeamAdmin();
  const isInvoiceAllowToView = () => {
    if (!currentPlan) {
      return false;
    }
    return (
      !isResellerClient &&
      !isFreeOrFreemiumPlan(currentPlan) &&
      isAllowAdmin() &&
      !isPaymentFailed
    );
  };
  const isSupportServicesAllowToView = () => {
    if (!currentPlan) {
      return false;
    }
    return (
      !isResellerClient && !isFreeOrFreemiumPlan(currentPlan) && isAllowAdmin()
    );
  };
  const isAddOnsAllowToView = () => {
    if (!currentPlan) {
      return false;
    }
    return (
      !isResellerClient && !isFreeOrFreemiumPlan(currentPlan) && isAllowAdmin()
    );
  };

  const isAddOnsAllowToEdit = () => {
    if (!currentPlan) {
      return false;
    }
    return (
      !isResellerClient && !isFreeOrFreemiumPlan(currentPlan) && isAllowAdmin()
    );
  };

  const isCompanySummaryAllowToView = () => {
    if (!currentPlan) {
      return false;
    }
    return !isResellerClient && isAllowAdmin();
  };

  const isPaymentMethodAllowToEdit = () => {
    if (!currentPlan) {
      return false;
    }
    return (
      !isResellerClient && !isFreeOrFreemiumPlan(currentPlan) && isAllowAdmin()
    );
  };

  const isManagePlanAllowToView = () => {
    if (!currentPlan) {
      return false;
    }
    return !isResellerClient && isAllowAdmin();
  };

  const isManageCustomObjectsAllowToView = () => {
    if (isCheckingCustomObjectAccess) return true;
    return canAccessCustomObjectData;
  };

  const isLabelsAllowToManage = isAllowAdmin();

  const isAuditLogAllowToView = () => isAllowAdmin();
  const isDataPrivacyAllowToView = () => {
    return (
      isAllowAdmin() &&
      (addOnStatus?.isPiiMaskingEnabled ||
        (import.meta.env.VITE_USER_NODE_ENV !== 'production' &&
          COMPANIES_TEST_PRIVACY.includes(companyId.data)))
    );
  };

  const isDeletedContactAllowToView = () => {
    return isAllowAdmin();
  };
  const isTicketingAllowToView = isAllowAdmin();

  const canEditCompanySleekFlowLabFeatures = isAllowAdmin();

  return {
    canEditCompanySleekFlowLabFeatures,
    isUsernameFieldAllowToView,
    isUsernameFieldAllowToEdit,
    isEmailFieldAllowToView,
    isEmailFieldAllowToEdit,
    isTeamFieldAllowToView,
    isTeamFieldAllowToEdit,
    is2FAFieldAllowToView,
    is2FARevokeWarningAllowToView,
    isQuickSharingTabAllowToView,
    is2FASettingAllowToView,
    isCompanyDetailTabAllowToView,
    isUserManagementTabAllowToView,
    isTeamManagementTabAllowToView,
    isInboxSettingsAllowToView,
    isWelcomeMessageAllowToView,
    isIpWhitelistingAllowToView,
    isInvoiceAllowToView,
    isSubscriptionsAllowToView,
    isSupportServicesAllowToView,
    isAddOnsAllowToView,
    isAddOnsAllowToEdit,
    isManageCustomObjectsAllowToView,
    isAuditLogAllowToView,
    isDataPrivacyAllowToView,
    isCompanySummaryAllowToView,
    isPaymentMethodAllowToEdit,
    isManagePlanAllowToView,
    isLabelsAllowToManage,
    isDeletedContactAllowToView,
    isTicketingAllowToView,
  };
};
