import { yupResolver } from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  useFilterCustomObjectListMutation,
  useUpdateCustomObjectMutation,
} from '@/api/customObject';
import { Button as LoadingButton } from '@/components/Button';
import Icon from '@/components/Icon';
import useSnackbar from '@/hooks/useSnackbar';

import { toApiUpdateCustomObject } from '../adapters';
import { checkCustomObjectNameExist } from '../shared/utils';
import { CustomObject } from '../type';

export function RenameCustomObject({
  customObject,
  onClose,
}: {
  customObject: CustomObject;
  onClose: () => void;
}) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const filterCustomObjectList = useFilterCustomObjectListMutation();
  const form = useForm({
    defaultValues: {
      name: customObject.name,
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .min(1, 'custom object name is required')
          .required()
          .test(
            'check-name-not-exist',
            'Custom object name already exited',
            (value, ctx) =>
              checkCustomObjectNameExist(
                value,
                ctx,
                filterCustomObjectList,
                'edit',
              ),
          ),
      }),
    ),
  });
  const inputValue = useWatch({ control: form.control, name: 'name' });
  const renameCustomObject = useUpdateCustomObjectMutation();

  const handleMenuClose = () => {
    onClose();
    setOpen(false);
    form.reset();
  };

  const onSubmit = (data: { name: string }) => {
    renameCustomObject.mutate(
      {
        ...toApiUpdateCustomObject(customObject),
        display_name: data.name,
      },
      {
        onSuccess: () => {
          handleMenuClose();
          snackbar.info(
            t('settings.custom-object.rename-success', {
              defaultValue: 'You have renamed the selected custom object',
            }),
          );
        },
        onError: () => {
          snackbar.error(t('general.generic-toast-error'));
        },
      },
    );
  };

  const disableSubmitButton =
    !!form.formState.errors['name'] || inputValue.trim() === customObject.name;

  return (
    <>
      <MenuItem
        onClick={() => {
          setOpen(true);
          onClose();
        }}
      >
        {t('settings.custom-object.rename.btn', {
          defaultValue: 'Rename',
        })}
      </MenuItem>
      <Dialog
        open={open}
        disableEnforceFocus={false}
        maxWidth={false}
        onClose={handleMenuClose}
      >
        <DialogTitle>
          <Stack
            m="16px 32px 0px 32px"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="headline2">
              {t('settings.custom-object.rename.title', {
                defaultValue: 'Rename custom object',
              })}
            </Typography>

            <IconButton onClick={handleMenuClose}>
              <Icon icon="x-close" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack m="16px 32px" width="788px" gap={3}>
              <TextField
                {...form.register('name')}
                error={!!form.formState.errors['name']}
                label={t('settings.custom-object.rename.object-name', {
                  defaultValue: 'object name',
                })}
                helperText={form.formState.errors['name']?.message}
              />
              <TextField
                label={t('settings.custom-object.object-id', {
                  defaultValue: 'object id',
                })}
                value={customObject.displayedId}
                disabled
              />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ m: '16px 32px 32px 0', p: 0 }}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={renameCustomObject.isPending}
              disabled={disableSubmitButton}
            >
              {t('settings.custom-object.done', {
                defaultValue: 'Done',
              })}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
