import {
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CustomObjectSchema,
  customObjectKeys,
  useReorderCustomObjectMutation,
} from '@/api/customObject';
import useSnackbar from '@/hooks/useSnackbar';

import { OBJECT_LOADING_LIMIT } from '../CustomObjectListTable';

export default function useCustomObjectTableDnd() {
  const { t } = useTranslation();
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const reorderObject = useReorderCustomObjectMutation();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  function handleDragStart(event: DragStartEvent) {
    setActiveId(event.active.id);
  }
  function handleDragCancel() {
    setActiveId(null);
  }
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (!over || !active) return;
    if (active.id !== over.id) {
      let priorToNewPositionId;

      queryClient.setQueriesData<
        InfiniteData<{ schemas: CustomObjectSchema[] }>
      >(
        {
          queryKey: customObjectKeys.getCustomObjectList({
            limit: OBJECT_LOADING_LIMIT,
          }),
        },
        (old) => {
          if (!old) return old;
          const pages = [];
          const allItems = old.pages.flatMap((page) => page.schemas);
          const oldIndex = allItems.findIndex(
            (item) => item.id == String(activeId),
          );
          const newIndex = allItems.findIndex(
            (item) => item.id == String(over.id),
          );
          const isMoveUp = oldIndex > newIndex;
          priorToNewPositionId =
            newIndex > 0 ? allItems[isMoveUp ? newIndex - 1 : newIndex].id : '';
          // When move to the top, the priorToNewPositionId is empty string

          const updatedItems = arrayMove(allItems, oldIndex, newIndex);
          for (let i = 0; i < updatedItems.length; i += OBJECT_LOADING_LIMIT) {
            pages.push({
              schemas: updatedItems.slice(i, i + OBJECT_LOADING_LIMIT),
            });
          }

          return {
            ...old,
            pages,
          };
        },
      );

      if (priorToNewPositionId === undefined) return;

      reorderObject.mutate(
        {
          activeId: String(active.id),
          priorToNewPositionId,
        },
        {
          onSuccess: () =>
            snackbar.info(
              t('settings.custom-object.reorder-success', {
                defaultValue:
                  'You have successfully reordered the custom object',
              }),
            ),
          onError: () => {
            snackbar.error(t('general.generic-toast-error'));
          },
        },
      );
    }
    setActiveId(null);
  }
  return {
    handleDragCancel,
    handleDragEnd,
    handleDragStart,
    activeId,
    sensors,
    isReordering: reorderObject.isPending,
  };
}
