import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { array, object, string } from 'yup';

import {
  useGetProviderUserMappingConfigQuery,
  useUpdateProviderUserMappingMutation,
} from '@/api/crmHub';
import { useSalesforceIntegrations } from '@/api/salesforce';
import { ReactComponent as CircleLogo } from '@/assets/logo/sleekflow-logo-circle.svg';
import { Button } from '@/components/Button';
import CircularLoader from '@/components/CircularLoader';
import Icon from '@/components/Icon';
import { IconButton } from '@/components/IconButton';
import PageHeaderWithBackButton from '@/components/PageHeaderWithBackButton';
import { ScrollArea } from '@/components/ScrollArea';
import { ROUTES } from '@/constants/navigation';
import useBackToURL from '@/hooks/useBackToURL';
import { useRouteWithLocale } from '@/hooks/useRouteWithLocale/useRouteWithLocale';
import useSnackbar from '@/hooks/useSnackbar';
import { getFullName } from '@/utils/messaging';

import SelectSalesforceUser from './SelectSalesforceUser';
import SelectSleekflowUser from './SelectSleekflowUser';

export default function SalesforceUserMapping() {
  const backToURL = useBackToURL({
    fallback: ROUTES.integrations.dashboard.salesforce,
  });
  const routeTo = useRouteWithLocale();
  const param = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const integrations = useSalesforceIntegrations();
  const organization = integrations.data?.find((i) => i.id === param.id);
  const updateProviderUserMapping = useUpdateProviderUserMappingMutation();
  const snackbar = useSnackbar();
  const { isLoading, data: providerUserMapping } =
    useGetProviderUserMappingConfigQuery(
      {
        providerName: 'salesforce-integrator',
        providerConnectionId: organization?.id ?? '',
      },
      {
        enabled: !!organization,
        select: (data) => {
          return {
            mappingId: data.user_mapping_config.id,
            userMappings: data.user_mapping_config.user_mappings.map((c) => ({
              sleekflowUser: {
                displayName:
                  c.sleekflow_user.display_name ??
                  c.sleekflow_user.email ??
                  t('general.unknown-label'),
                id: c.sleekflow_user.id,
                email: c.sleekflow_user.email,
              },
              salesforceUser: {
                displayName: getFullName({
                  firstName: c.provider_user.first_name,
                  lastName: c.provider_user.last_name,
                  fallback: c.provider_user.email ?? t('general.unknown-label'),
                }),
                id: c.provider_user.id,
                email: c.provider_user.email,
              },
            })),
          };
        },
      },
    );

  const form = useForm({
    defaultValues: {
      userMappings: providerUserMapping?.userMappings.length
        ? providerUserMapping?.userMappings
        : [
            {
              sleekflowUser: {
                displayName: '',
                id: '',
                email: '',
              },
              salesforceUser: {
                displayName: '',
                id: '',
                email: '',
              },
            },
          ],
    },
    values: {
      userMappings: providerUserMapping?.userMappings.length
        ? providerUserMapping?.userMappings
        : [
            {
              sleekflowUser: {
                displayName: '',
                id: '',
                email: '',
              },
              salesforceUser: {
                displayName: '',
                id: '',
                email: '',
              },
            },
          ],
    },
    resolver: yupResolver(
      object({
        userMappings: array().of(
          object({
            sleekflowUser: object({
              id: string().required(t('general.required-field-validation')),
            }).test('required', (value, ctx) => {
              return value.id
                ? true
                : ctx.createError({
                    message: t('general.required-field-validation'),
                  });
            }),
            salesforceUser: object({
              id: string().required(t('general.required-field-validation')),
            }).test('required', (value, ctx) => {
              return value.id
                ? true
                : ctx.createError({
                    message: t('general.required-field-validation'),
                  });
            }),
          }),
        ),
      }),
    ),
  });
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'userMappings',
  });

  const mappedUsers = useWatch({
    control: form.control,
    name: 'userMappings',
  });

  return (
    <>
      <PageHeaderWithBackButton
        title={t('integrations.nav.manage-settings', {
          defaultValue: 'Manage settings',
        })}
        onBackButtonClicked={() => navigate(backToURL)}
        subtitle={t('integrations.nav.integration', {
          defaultValue: 'Integration',
        })}
        action={
          <Button
            loading={updateProviderUserMapping.isPending}
            variant="contained"
            onClick={form.handleSubmit((data) => {
              if (!data || !data.userMappings) {
                return;
              }
              const userMappings = data.userMappings
                .filter((m) => m.salesforceUser.id && m.sleekflowUser.id)
                .map((m) => ({
                  sleekflow_user_id: m.sleekflowUser.id,
                  provider_user_id: m.salesforceUser.id,
                }));
              updateProviderUserMapping.mutate(
                {
                  provider_name: 'salesforce-integrator',
                  provider_user_mapping_config_id:
                    providerUserMapping?.mappingId ?? '',
                  user_mappings: userMappings,
                },
                {
                  onSuccess: () => {
                    navigate(
                      routeTo(
                        generatePath(ROUTES.integrations.dashboard.salesforce),
                      ),
                    );
                    snackbar.info(
                      t('integrations.salesforce.save-success', {
                        defaultValue:
                          'You have updated the settings for {organizationName}',
                        organizationName: organization?.name ?? 'Salesforce',
                      }),
                    );
                  },
                  onError: () => {
                    snackbar.error(
                      t('general.something-went-wrong.description'),
                    );
                  },
                },
              );
            })}
          >
            {t('integrations.salesforce.save', {
              defaultValue: 'Save',
            })}
          </Button>
        }
      />

      <Stack
        sx={{
          p: '40px 80px',
        }}
        spacing={3}
      >
        <PageTitle organizationName={organization?.name ?? 'Salesforce'} />
        <ScrollArea
          sx={{
            height: 'calc(100svh - 320px)',
          }}
        >
          {isLoading ? (
            <Stack height="60svh">
              <CircularLoader />
            </Stack>
          ) : (
            <Grid
              container
              spacing={2}
              sx={{
                alignItems: 'center',
                width: '885px',
              }}
            >
              <Grid item xs={5}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="headline4">
                    {t('integrations.salesforce.sleekflow-users', {
                      defaultValue: 'SleekFlow Users',
                    })}
                  </Typography>
                  <CircleLogo
                    width="20px"
                    height="20px"
                    style={{ flexShrink: 0 }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={5}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="headline4">
                    {t('integrations.salesforce.salesforce-users', {
                      defaultValue: 'Salesforce Users',
                    })}
                  </Typography>
                  <Icon icon="salesforce" size={20} />
                </Stack>
              </Grid>

              {fields.map((value, idx) => {
                return (
                  <React.Fragment key={value.id}>
                    <Grid item xs={5}>
                      <Controller
                        control={form.control}
                        name={`userMappings.${idx}.sleekflowUser`}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <FormControl error={!!error}>
                              <SelectSleekflowUser
                                mappedUsers={
                                  mappedUsers
                                    ? mappedUsers.map((f) => f.sleekflowUser.id)
                                    : []
                                }
                                onChange={onChange}
                                value={value}
                              />
                              {error?.message && (
                                <FormHelperText>
                                  {error?.message}
                                </FormHelperText>
                              )}
                            </FormControl>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Icon icon="switch-horizontal" />
                    </Grid>
                    <Grid item xs={5}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Controller
                          control={form.control}
                          name={`userMappings.${idx}.salesforceUser`}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => {
                            return (
                              <FormControl error={!!error}>
                                <SelectSalesforceUser
                                  mappedUsers={
                                    mappedUsers
                                      ? mappedUsers.map(
                                          (f) => f.salesforceUser.id,
                                        )
                                      : []
                                  }
                                  providerConnectionId={organization?.id}
                                  onChange={onChange}
                                  value={value}
                                />
                                {error?.message && (
                                  <FormHelperText>
                                    {error?.message}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            );
                          }}
                        />
                        <IconButton onClick={() => remove(idx)}>
                          <Icon icon="trash" />
                        </IconButton>
                      </Stack>
                    </Grid>
                  </React.Fragment>
                );
              })}

              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  startIcon={<Icon icon="plus-circle" />}
                  onClick={() => {
                    append({
                      sleekflowUser: {
                        displayName: '',
                        id: '',
                        email: '',
                      },
                      salesforceUser: {
                        displayName: '',
                        id: '',
                        email: '',
                      },
                    });
                  }}
                >
                  {t('integrations.salesforce.add-new-mapping', {
                    defaultValue: 'Add new mapping',
                  })}
                </Button>
              </Grid>
            </Grid>
          )}
        </ScrollArea>
      </Stack>
    </>
  );
}

function PageTitle({ organizationName }: { organizationName: string }) {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        padding: '20px',
        border: '1px solid',
        borderColor: 'gray.30',
        borderRadius: 1,
      }}
      direction="row"
      spacing={2}
    >
      <Icon icon="salesforce" size={32} />
      <Stack spacing={0.5}>
        <Typography variant="headline2">
          {t('integrations.salesforce.title', {
            defaultValue: 'Map users between SleekFlow and {organizationName}',
            organizationName: organizationName ?? 'Salesforce',
          })}
        </Typography>
        <Typography variant="body1">
          {t('integrations.salesforce.subtitle', {
            defaultValue:
              'Map SleekFlow users to their corresponding Salesforce users in {organizationName} to seamlessly sync data between the platforms. Once the mapping is set up, contacts will automatically be assigned to the corresponding users in both SleekFlow and Salesforce.',
            organizationName: organizationName ?? 'Salesforce',
          })}
        </Typography>
      </Stack>
    </Stack>
  );
}
